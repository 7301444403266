import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import DrawerMenu from '../components/DrawerMenu'
import Cart from '../components/Cart'

import { breakpoints } from '../utils/styles'

const BodyLargeWrap = styled.div`
  ${tw`p-12 md:p-20 my-20 md:my-32`}

  p {
    margin-bottom: 3rem;
  }
`

const Content = styled.div`
  ${tw`mx-auto max-w-5xl font-extended`}
  font-size: 20px;
  line-height: 28px;
  @media (min-width: ${breakpoints.md}px) {
    line-height: 1.45;
    font-size: 1.75rem;
  }

  h1 {
    ${tw`mb-8`}
  }

  p {
    ${tw`mb-2`}
  }

  a {
    ${tw`underline`}
  }
`

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Header />
    <DrawerMenu />
    <Cart />
    <BodyLargeWrap>
      <Content>
        <h1>NOT FOUND</h1>
        <p>We couldn't find the content you were looking for.</p>
        <p>
          Please <Link to="/">click here</Link> to return home.
        </p>
      </Content>
    </BodyLargeWrap>
    <Footer />
  </>
)

export default NotFoundPage
